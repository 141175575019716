import React from 'react';
import { Link } from "react-scroll";
 // SCSS
import './navbar.scss';
// Assets
import LogoImg from '../../assets/navbar/VCSS_Logo.png';
import MobileMenuIcon from '../../assets/navbar/mobile-menu.svg';

const desktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? "extraLargeNavbar" : ""}`}>
    <div className="wrapper flex-s-between">
      <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
      >
         <a href='/'>
            <img src={LogoImg} alt="logo" style={{
              maxWidth: "70%",
            }} />
         </a>{' '}
         <a href='/'>
          <h1
              style={{
                color: "#0d0b4e",
                marginLeft: "15px",
                fontFamily:"EB Garamond",
                fontSize: "3rem",
                letterSpacing:"3px",
                fontWeight:"500"
              }}
          >
            VCSS
          </h1>
          </a>
      </div>
      {
        window.location.pathname === '/terms-and-conditions' || window.location.pathname=== '/privacy-policy' || window.location.pathname==='/disclaimer' ? null :
        <div className="mobile__menu" onClick={props.mobileMenuOpen}>
        <img src={MobileMenuIcon} alt="menu" />
      </div>
      }
      <div className="desktop__menu">
        <ul className="flex-s-between">
        <li>
            <Link activeClass="active-link" to="hero" spy={true} smooth={true} offset={-70} duration={500}>
              HOME
            </Link>
          </li> 
        <li>
            <Link activeClass="active-link" to="blog" spy={true} smooth={true} offset={-70} duration={500}>
              SERVICES
            </Link>
          </li> 
          <li>
            <Link activeClass="active-link" to="about" spy={true} smooth={true} offset={-70} duration={500}>
              ABOUT US
            </Link>
          </li>
          <li>
            <Link activeClass="active-link" to="contact" spy={true} smooth={true} offset={-70} duration={500}>
              CONTACT US
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default desktopNav;